import './config.js';
import { increment_metric, splash, splash_close } from './brokenwrenches.js';
import { confirmEmail, confirmPassword, forgotPassword, getSession, getUsername, getUsernameFromApi, signIn, signOut, signUp } from './auth-cognito.js';

export const get_session = (callable, errorCallable) => getSession(callable, errorCallable);
export const get_username = () => getUsername();

export function show_signup(){
  if (getSession()){
    let html = `You are already logged in. You must logoff to sign up!
        <div class=button_container><button class=logout>Logoff</button></div>`;
    splash(html);

  } else {
    let html = `<div class=splash_logo>${window._config.site_name}</div>
        <div class=splash_error></div>
        <div class=splash_text>Create your free account and get started now!</div>
        <form id=sign_up action="">
        Desired Username:<input type=text id=username autocomplete=username autocorrect=off autocapitalize=none spellcheck=off><br>
        Email:<input type=email id=email spellcheck=off><br>
        Password:<input type=password id=password autocomplete=new-password><br>`;

    let source, campaign, site;
    let dimensions = [];

    if (document.cookie.indexOf('source=') > -1){
      source = document.cookie.substr(document.cookie.indexOf('source=') + 7);
      if (source.search('[&;]') > -1){
        source = source.substr(0, source.search('[&;]'));
      }
      html = html.concat(`<input type=hidden id=source value="${source}">`);
      dimensions.push({'Name': 'source', 'Value': source});
    }

    if (document.cookie.indexOf('campaign=') > -1){
      campaign = document.cookie.substr(document.cookie.indexOf('campaign=') + 9);
      if (campaign.search('[&;]') > -1){
        campaign = campaign.substr(0, campaign.search('[&;]'));
      }
      html = html.concat(`<input type=hidden id=campaign value="${campaign}">`);
      dimensions.push({'Name': 'campaign', 'Value': campaign});
    }

    html = html.concat(`<input type=hidden id=site value="${window._config.site_name}">`);
    dimensions.push({'Name': 'site',
        'Value': window._config.site_name.substr(0, window._config.site_name.length - 4)});
    increment_metric('signup-start', dimensions);

    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);
    document.querySelector("#sign_up > #username").focus();
  }
}

export function show_login(url){
  let html = `<div class=splash_logo>${window._config.site_name}</div>
      <div class=splash_error></div> <form id=signin>
      Username or email:<input type=text id=u autocomplete=username autocorrect=off autocapitalize=none spellcheck=off><br>
      Password:<input type=password id=p autocomplete=current-password><br>`;
  if (url){
    html = html.concat(`<input type=hidden id=url value='${url}'><br>`);
  }
  html = html.concat(`<div class=button_container><button class=submit>Login</button>
      <button class=forgot-password>Forgot Password</button></div></form>`);
  splash(html);
  document.querySelector("#signin > #u").focus();
}

document.addEventListener('click', (event) => {
  if (event.target.matches('a.show_signup')){
    event.preventDefault();
    show_signup();

  } else if (event.target.matches('#sign_up button.submit')){
    event.preventDefault();

    document.querySelector('.splash_error').textContent = '';
    let attributes = {};
    const username = document.querySelector("#username")?.value.trim();
    const email = document.querySelector("#email")?.value.trim();
    attributes['email'] = email;

    if (username.search('[^A-Za-z0-9._\-]') > -1){
      document.querySelector('.splash_error').textContent = `'${username.substr(username.search('[^A-Za-z0-9._\-]'), 1)}' is not allowed in usernames.`;
      return;
    }
    if (username.search('.') == -1){
      document.querySelector('.splash_error').textContent = 'Username is required.';
      return;
    }
    if (email.search('.@.') == -1){
      document.querySelector('.splash_error').textContent = 'A valid email address is required.';
      return;
    }
    if (email.search(' ') > -1){
      document.querySelector('.splash_error').textContent = `' ' is not allowed in email addresses.`;
      return;
    }
    if (document.querySelector("#password")?.value.search('........') == -1){
      document.querySelector('.splash_error').textContent = 'Password must be at least eight characters.';
      return;
    }

    if (document.querySelector("#name")?.value)
      attributes['name'] = document.querySelector("#name").value;
    if (document.querySelector("#email_updates")?.matches(':checked'))
      attributes['email_updates'] = 'true';
    if (document.querySelector("#privacy_consent")?.matches(':checked'))
      attributes['privacy_consent'] = 'true';
    if (document.querySelector("#interests")?.value)
      attributes['interests'] = document.querySelector("#interests").value;
    if (document.querySelector("#source")?.value)
      attributes['source'] = document.querySelector("#source").value;
    if (document.querySelector("#campaign")?.value)
      attributes['campaign'] = document.querySelector("#campaign").value;
    if (document.querySelector("#site")?.value)
      attributes['site'] = document.querySelector("#site").value;

    signUp(username, document.querySelector("#password")?.value, attributes,
        (err, result) => {
          if (err) {
            let error_string = err.message.toString();
            if (error_string.indexOf('#BW#') > -1){
              const start = error_string.indexOf('#BW#') + 4;
              const end = error_string.slice(start).indexOf('#BW#') + start;
              error_string = error_string.slice(start, end);
            }
            document.querySelector('.splash_error').textContent = error_string;

          } else {
            let html = `<div class=splash_logo>${window._config.site_name}</div>
                <div class=splash_error></div><form id=sign_up action="">
                Verification Code:<input type=text id=c><br>
                <input type=hidden id=username value='${username}'><br>
                <div class=button_container><button class=signup-confirm>Submit</button></div></form>`;
            splash(html);
            document.querySelector('.splash_error').textContent = 'Check your email for a verification code and enter it below.';
            document.querySelector("#sign_up > #c").focus();
          }
        });

  } else if (event.target.matches('#sign_up button.signup-confirm')){
    event.preventDefault();

    if (document.querySelector("#c")?.value.length < 1){
      document.querySelector('.splash_error').textContent = 'Please enter a verification code.';
    } else {
      confirmEmail(document.querySelector("#username")?.value, document.querySelector("#c")?.value, (err, result) => {
        if (err) {
          let error_string = err.message.toString();
          document.querySelector('.splash_error').textContent = error_string;

        } else {
          let html = `Your account has been successfully created.
              Please <a href="" class=show_login>login</a> to get started!`;
          splash_close(html);
        }
      });
    }
  } else if (event.target.matches('button.splash_close_signup')){
    splash_close();
    window.location = '/?s=signup-close';

  } else if (event.target.matches('a.show_login')){
    event.preventDefault();
    show_login();

  } else if (event.target.matches('#signin button.login, #signin button.submit')){
    event.preventDefault();

    signIn(document.querySelector("#signin #u")?.value.trim(),
        document.querySelector("#signin #p")?.value,
        document.querySelector("#signin #url")?.value,
        () => {
          getUsernameFromApi(() => {
            document.cookie = "source=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie = "campaign=; expires=Thu, 01 Jan 1970 00:00:00 GMT";

            if (document.querySelector("#signin #url")?.value){
              window.location.href = document.querySelector("#signin #url").value;
            } else {
              if (window._config.site_name == 'brokenwrenches.com'){
                if (window._config.stage == 'local'){
                  window.location.href = '/user';
                } else {
                    window.location.href = '/' + getUsername();
                }
              } else {
                window.location.href = '/';
              }
            }
          });
        },
        (err) => {
          let error_string = err.message.toString();
          if (error_string == 'PASSWORD parameter is required'){
            error_string = 'Incorrect username or password.';
          } else if (error_string.startsWith('Exception migrating user')){
            error_string = 'Incorrect username or password.';
          } else if (error_string.startsWith('User does not exist')){
            error_string = 'Incorrect username or password.';
          }
          document.querySelector('.splash_error').textContent = error_string;
        });

  } else if (event.target.matches('#signin button.forgot-password')){
    event.preventDefault();

    if (document.querySelector("#signin #u")?.value.length < 1){
      document.querySelector('.splash_error').textContent = 'Please enter a username.';

    } else {
      forgotPassword(document.querySelector("#signin #u").value,
          () => {
            let html = `<div class=splash_logo>${window._config.site_name}</div>
                <div class=splash_error></div><form id=signin>
                <input type=hidden id=u value="${document.querySelector("#signin #u").value}">
                Verification Code:<input type=text id=c><br>
                New Password:<input type=password id=p><br><div class=button_container>
                <button class=confirm-password>Change Password</button></div></form>`;
            splash(html);
            document.querySelector('.splash_error').textContent = 'Check your email for a verification code to enter below.';
            document.querySelector("#signin > #c").focus();
          },
          (err) => {
            let error_string = err.message.toString();
            if (error_string == 'Username/client id combination not found.'){
              error_string = 'Unknown username.';
            }
            document.querySelector('.splash_error').textContent = error_string;
          });
    }

  } else if (event.target.matches('#signin button.confirm-password')){
    event.preventDefault();

    if (document.querySelector("#signin #p")?.value.search('........') == -1){
      document.querySelector('.splash_error').textContent = 'Password must be at least eight characters.';
      return;
    }

    confirmPassword(document.querySelector("#signin #u").value, document.querySelector("#signin #c")?.value,
        document.querySelector("#signin #p").value,
        () => {
          let html = `Your password has been successfully changed.
              Please <a href="" class=show_login>login</a> to get started!`;
          splash_close(html);
        },
        (err) => {
          let error_string = err.message.toString();
          document.querySelector('.splash_error').textContent = error_string;
        });

  } else if (event.target.matches('a.logo')){
    event.preventDefault();
    if (getUsername() == window._config.demo_username){
      signOut();
    }
    window.location.href = '/?s=header-click';
  
  } else if (event.target.matches('button.logout, i.logout, a.logout')){
    signOut();
    window.location.href = '/?s=logout';
  
  } else if (event.target.matches('a.start_demo')){
    event.preventDefault();

    signIn(window._config.demo_username, window._config.dp, '', 
        () => {
          getUsernameFromApi(() => {
            document.cookie = "source=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie = "campaign=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            if (window._config.site_name == 'brokenwrenches.com'){
              if (window._config.stage == 'local'){
                window.location.href = '/user';
              } else {
                  window.location.href = '/' + getUsername();
              }
            }
            increment_metric('demo-start');
          });
        },
        (err) => {
          increment_metric('demo-start-error');
        });

  } else if (event.target.matches('a.demo_signup')){
    event.preventDefault();
    signOut();
    window.location.href = '/signup';
  }
});
